import React, { Component } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "./App.css";
import { createGlobalStyle } from "styled-components";
import MainScreen from "./containers/screens/MainScreen";
import GameScreen from "./containers/screens/GameScreen";
import AboutScreen from "./containers/screens/AboutScreen";
import tut0 from "./resources/tutorial0.gif";
import tut1 from "./resources/tutorial2.gif";
import AboutHeaderImg from "./resources/about1.png";
import AboutFooterImg from "./resources/about2.png";
import { Button } from "./components/Button";

const GlobalStyles = createGlobalStyle`
body{
  margin:0px;
  padding:0px;
  overflow:hidden;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -ms-touch-action: none;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  min-width: 300px;
  min-height: 300px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;

class App extends Component {
  componentDidMount() {
    this.img1 = new Image();
    this.img2 = new Image();

    this.img1.src = { tut0 };
    this.img2.src = { tut1 };

    this.img3 = new Image();
    this.img4 = new Image();

    this.img3.src = { AboutHeaderImg };
    this.img4.src = { AboutFooterImg };

    document.body.addEventListener(
      "touchstart",
      function(e) {
        
         // e.preventDefault();
        
      },
      { passive: false }
    );
    document.body.addEventListener(
      "touchend",
      function(e) {
        
        //  e.preventDefault();
        
      },
      { passive: false }
    );
    document.body.addEventListener(
      "touchmove",
      function(e) {
        
         // e.preventDefault();
        
      },
      { passive: false }
    );
  }

  render() {
    return (
      <Router>
        <React.Fragment>
          <ContentWrapper>
            <GlobalStyles />

            <Route exact path="/" component={MainScreen} />
            <Route path="/game" component={GameScreen} />
          </ContentWrapper>
          <Route path="/about" component={AboutScreen} />
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
