import React, { Component } from 'react'
import styled from 'styled-components';
import {UndoIcon} from '../Icons';

const Button = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:136px;
height:55px;
box-shadow: ${props => props.muted ? "none" : "0px 8px 0px rgba(0, 0, 0, 0.25)"};
border: none;
border-radius: 8px;
background: ${props => props.muted ? "#00324C" : "#006499"};
color: #CCEDFF;
opacity: ${props => props.muted ? 0.5 : 1};
outline: none;
-webkit-tap-highlight-color: transparent;
transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
pointer-events:auto;
touch-action: manipulation;

&: active {
    transform: ${props => props.muted ? "none" : "translateY(5px)"};
    box-shadow: ${props => props.muted ? "none" : "0px 2px 0px rgba(0, 0, 0, 0.5)"};
  }
`;

const ButtonText = styled.span`
align-self: stretch;
font-family: 'Do Hyeon', sans-serif;
font-size:20px;
padding-left:8px;
`

export const UndoButton = props =>(
    
    <Button {...props}>
        <UndoIcon color="#CCEDFF"/>
        <ButtonText>Undo</ButtonText>
</Button>);





