import React from "react";
import styled, {keyframes} from "styled-components";
import Timer from '../game/Timer'

const fadeInUp = keyframes`
from {
    opacity:0;
    transform: translateY(200%);
  }

  to {
    opacity:1;
    transform: translateY(0%);
  }
`;


const HeaderWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 120px;
  left: 0px;
  top: 0px;
  background: ${props => {
    if (props.one){
      return "#0085CC"
    } else if (props.two) {
      return "#CC6200"
    } else {
      return "#5B0BA2"
    }}}
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 8px 8px;
  font-family: Do Hyeon;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;

const PlayerTitleWrapper = styled.div`
  flex: 1 1 auto;
  font-size: 20px;
  color: #ffefee;
  padding-top: 1rem;
  padding-left: 1rem;
`;

const FeedbackWrapper = styled.span`
  font-size: 20px;
  color: #ffefee;
  padding-right: 1rem;
  float: right;
  background: ${props => {
    if(props.lineCount > 3){
      return "red"
    } else {return "none"}
  }}
`;

const PlayerInstructionWrapper = styled.div`
  flex: 2 2 auto;
  justify-content:center;
  align-self: center;
  text-align: center;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => (props.one ? "20px" : "1.2rem")};
  color: #ffffff;
  width: 100%;
`;


export default class Header extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  state = {
    playerId: 1
  };

  render() {
    const playerId = this.props.player;

    let TitleText;
    let InstructionText;
    let LineCount;
    let Countdown;
    

    if (playerId == 1) {
      TitleText = "🐵  PLAYER ONE";
      InstructionText = "Draw up to three random lines for the next player.";
      LineCount = "✏️ " + this.props.lineCount + "/3";
    } else if (playerId == 2){
      TitleText = "🐵  PLAYER TWO!";
      InstructionText = "You are drawing...";
      console.log('Timer: ' + this.props.timerStatus)
      Countdown = <Timer status={this.props.timerStatus} onClearTimer={this.props.onClearTimer}/>
    } else {
      TitleText = "👋 WELCOME!";
      InstructionText = "Here’s a quick guide to get you started with Squarebusters.";
    }

    if(this.props.text){
      InstructionText = this.props.text;
    }

    const HeaderContent = (
      <React.Fragment>
        <PlayerTitleWrapper>
          {TitleText}
          <FeedbackWrapper>{LineCount}{Countdown}</FeedbackWrapper>
          
        </PlayerTitleWrapper>
        <PlayerInstructionWrapper>
          {InstructionText}
        </PlayerInstructionWrapper>
        
        
      </React.Fragment>
    );

    if (playerId == 1){
      return <HeaderWrapper one>{HeaderContent}</HeaderWrapper>;
    }
      
    else if (playerId == 2) {
      return <HeaderWrapper two>{HeaderContent}</HeaderWrapper>;
  } else {
    return <HeaderWrapper>{HeaderContent}</HeaderWrapper>;
  }}
}
