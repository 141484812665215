import React from "react";
import styled, { keyframes } from "styled-components";
import Header from "../../components/game/Header";
import { UndoButton } from "../../components/buttons/UndoButton";
import { TickButton } from "../../components/buttons/TickButton";
import Canvas from "../../components/game/Canvas";
import { ChallengeInput } from "../../components/input/ChallengeInput";
import { ChallengeButton } from "../../components/buttons/ChallengeButton";


const fadeInUp = keyframes`
from {
    opacity:0;
    transform: translateY(200%);
  }

  to {
    opacity:1;
    transform: translateY(0%);
  }
`;

const shrink = keyframes`
from {
    opacity:1;
    transform: scale(1);
    pointer-events:auto;
    transform: translateY(0px);
  }

  to {
    opacity:0.8;
    transform: scale(0.8);
    pointer-events:none;
    
  }
`;

const AnimatedButton = styled.div`
  animation: ${fadeInUp} 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1;
  animation-delay: ${props => props.delay};
  animation-fill-mode: backwards;
`;

const StyledCanvasDraw = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  top: 136px;
  bottom: 100px;
  pointer-events: auto;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #ff6c64;
  box-sizing: border-box;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  animation: ${props => (props.status == "CHOOSING_WORD" ? shrink : fadeInUp)}
    0.8s cubic-bezier(0.19, 1, 0.22, 1) 1;
  animation-delay: 0s;
  animation-fill-mode: ${props => (props.status == "CHOOSING_WORD" ? "forwards" : "backwards")};
`;

const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => (props.status == "CHOOSING_WORD" ? "#342C3A" : "#504458")} ;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
  width: 100%;
  height: 100%;
`;

const StyledButtonsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100px;
  pointer-events: none;
  visibility: show;

  a {
    text-decoration: none;
  }
`;

const StyledInputWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 8px;
  right: 8px;

  @media (min-width: 700px) {
    left: 150px;
  right: 150px;
  }

  @media (min-width: 1000px) {
    left: 300px;
  right: 300px;
  }

  pointer-events: auto;
  animation: ${props => (props.status == "CHOOSING_WORD" ? fadeInUp : "none")}
     cubic-bezier(0.19, 1, 0.22, 1) 1;
  animation-delay: 0.2s;
  animation-fill-mode: backwards;
`;

export default class DrawingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "#ffc600",
      brushRadius: 5,
      canvasDraw: null,
      lineCount: 0,
      challengeText: "",
      challengeButtonState: false
    };
  }

  componentDidMount() {}

  handleUndo = () => {
    console.log("Handling undo");
    if (this.lineCount <= 0) return;
    this.setState({ lineCount: this.state.lineCount - 1 });
  };

  handleAddLine = () => {
    console.log("Handling new line");
    if (this.state.lineCount < 3) {
      this.setState({ lineCount: this.state.lineCount + 1 });
    }
  };

  handleInputChange = event => {
    if (event.target.value.length == 0) {
      this.setState({
        challengeText: "",
        challengeButtonState: false
      });
    } else {
      this.setState({
        challengeText: event.target.value,
        challengeButtonState: true
      });
    }
  };

  getChallengeText = () => {
    this.props.onSetChallenge(this.state.challengeText);
  };

  challengeButtonHandler = () => {
    if (this.state.challengeButtonState) {
      return <ChallengeButton onClick={this.getChallengeText} />;
    } else {
      return <ChallengeButton muted />;
    }
  };

  handleAccept = () => {
    const savedCanvas = this.canvasDraw.getSaveData();
    this.props.onSetCanvas(savedCanvas);
  };

  render() {

    let undobutton;
    let tickbutton;
    let animatedDrawingButtons;
    let challengebutton;
    let animatedChallengeButton;
    let animatedInputWrapper;

    if (this.props.status == "DRAWING") {
      if (this.state.lineCount == 0) {
        undobutton = <UndoButton muted />;
        tickbutton = <TickButton muted text="Accept" />;
      } else {
        undobutton = <UndoButton onClick={this.handleUndo} />;
        if (this.state.lineCount > 3) {
          tickbutton = <TickButton muted text="Accept" />;
        } else {
          tickbutton = <TickButton onClick={this.handleAccept} text="Accept" />;
        }
      }

      animatedDrawingButtons = (
        <React.Fragment>
          <AnimatedButton delay="0.4s">{undobutton}</AnimatedButton>
          <AnimatedButton delay="0.6s">{tickbutton}</AnimatedButton>
        </React.Fragment>
      );
    } else if (this.props.status == "CHOOSING_WORD") {
      if (this.state.challengeButtonState) {
        challengebutton = <ChallengeButton onClick={this.getChallengeText} />;
      } else {
        challengebutton = <ChallengeButton muted />;
      }
      animatedChallengeButton = (
        <AnimatedButton delay="0.3s">{challengebutton}</AnimatedButton>
      );

      animatedInputWrapper = (
        <StyledInputWrapper status={this.props.status}>
          <ChallengeInput onChange={this.handleInputChange} />
        </StyledInputWrapper>
      );
    }

    return (
      <StyledBackground status={this.props.status}>
        <StyledCanvasDraw status={this.props.status}>
          <Canvas
            ref={canvasDraw => (this.canvasDraw = canvasDraw)}
            width={this.props.canvasWidth}
            height={this.props.canvasHeight}
            onAddLine={this.handleAddLine}
            maxLines = {3}
            lineCount={this.state.lineCount}
          />
        </StyledCanvasDraw>
        <Header player={1} lineCount={this.state.lineCount} />

        {animatedInputWrapper}

        <StyledButtonsWrapper>
          {animatedDrawingButtons}
          {animatedChallengeButton}
        </StyledButtonsWrapper>
      </StyledBackground>
    );
  }
}
