import React, { Component } from "react";
import styled from "styled-components";
import { MemoryRouter as Router, Redirect, Route, Switch, Link } from "react-router-dom";
import DrawingScreen from "./DrawingScreen";
import ChallengeScreen from "./ChallengeScreen";
import ReviewScreen from "./ReviewScreen";
import TutorialScreen from "./TutorialScreen";
import ImageScreen from "./ImageScreen";

export default class GameScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savedCanvas: null,
      canvasWidth: 400,
      canvasHeight: 400,
      challengeWord: "",
      status: "TUTORIAL",
      dataURL: ""
    };
  }

  componentDidMount(){
    
   let tutorialState = sessionStorage.getItem('tutorial');
   console.log(`Tutorial state is ${tutorialState}`)
   if(tutorialState == "off"){
     this.setState({
       status:"DRAWING"
     });
   } else {
     this.setState({
       status:"TUTORIAL"
     });
   }}

  handleSetCanvas = savedCanvas => {
    
    this.setState({
      savedCanvas: savedCanvas,
      status: "CHOOSING_WORD"
    });
    console.log("Canvas saved.");
  };

  handleSetChallenge = word => {
    console.log(word);
    const wordString = word.toString();
    this.setState({
      challengeWord: wordString,
      status: "CHALLENGE_BEGIN"
    });
  };

  handleBeginClick = () => {
    this.setState({
      status: "CHALLENGE_STARTED"
    });
  };

  handleDoneClick = savedCanvas => {
    this.setState({
      savedCanvas: savedCanvas,
      status: "REVIEW"
    });
    console.log("Canvas saved.");
  };

  handleRematch = () => {
    console.log("Rematch");

    this.setState({
      savedCanvas: null,
      challengeWord: "",
      status: "DRAWING"
    });
  }

  handleSave = dataURL => {
    console.log("save");

    var img = new Image()
    img.src = dataURL;
    var newTab = window.open();
newTab.document.body.append(img);
  }

  handleGameStart = () => {
    console.log("Tutorial complete");
    sessionStorage.setItem('tutorial','off');
    this.setState({
      status: "DRAWING"
    });
  }
 
  render() {

    this.state.canvasWidth = window.innerWidth - 16;
    this.state.canvasHeight = window.innerHeight - 100 - 120 - 16;

    console.log(this.state);
    const currentScreen = props => {
      if (
        this.state.status == "CHALLENGE_BEGIN" ||
        this.state.status == "CHALLENGE_STARTED"
      ) {
        return (
          <ChallengeScreen
            {...props}
            canvasWidth={this.state.canvasWidth}
            canvasHeight={this.state.canvasHeight}
            onBeginClick={this.handleBeginClick}
            onDoneClick={this.handleDoneClick}
            challengeWord={this.state.challengeWord}
            savedCanvas={this.state.savedCanvas}
            onSetCanvas={this.handleSetCanvas}
            status={this.state.status}
          />
        );
      } else if (
        this.state.status == "DRAWING" ||
        this.state.status == "CHOOSING_WORD"
      ) {
        return (
          <DrawingScreen
            {...props}
            canvasWidth={this.state.canvasWidth}
            canvasHeight={this.state.canvasHeight}
            onSetCanvas={this.handleSetCanvas}
            onSetChallenge={this.handleSetChallenge}
            status={this.state.status}
          />
        );
      } else if (this.state.status == "REVIEW") {
        return (<ReviewScreen
        {...props}
        canvasWidth={this.state.canvasWidth}
        canvasHeight={this.state.canvasHeight}
        savedCanvas={this.state.savedCanvas}
        onRematch={this.handleRematch}
        onSave={this.handleSave}
        status={this.state.status}/>);
      } else if (this.state.status == "SAVE") {

      
       
      } else if (this.state.status == "TUTORIAL"){
       return (<TutorialScreen
       onGameStart={this.handleGameStart}/>)
      } else {
        return <Redirect to='/'/>
      }
    };

    return <Route path="/game" render={currentScreen} />;
  }
}
