import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import AboutHeaderImg from "../../resources/about1.png";
import AboutFooterImg from "../../resources/about2.png";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


const fadeIn = keyframes`
from {
    visibility: visible;
    opacity:0;
    
  }

  to {
    visibility: visible;
    opacity:1;
  }
`;

const StyledBackground = styled.div`
  display: block;
  flex-direction: column;
  background: #e5e5e5;
  overflow: scroll;
  width: 100%;
  color: white;
`;

const StyledAboutText = styled.div`
visibility: hidden;
  font-family: Do Hyeon;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  overflow: auto;
  color: #504458;

  animation: ${props => props.status == "loaded" ? fadeIn : "none"} 0.3s ease-out 1;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
`;

const StyledHeaderText = styled.p`
visibility: hidden;
  font-family: Jua;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 32px;
  text-align: center;
  color: #7a0fd7;
  margin: 0px;
  padding-top: 50px;
  animation: ${props => props.status == "loaded" ? fadeIn : "none"} 0.3s ease-out 1;0.3s ease-out 1;
  animation-delay: 0s;
  animation-fill-mode: forwards;
`;

const StyledHeaderImg = styled.img`
visibility: hidden;
  position: relative;
  width: 100%;
  animation: ${props => props.status == "loaded" ? fadeIn : "none"} 0.3s ease-out 1; 0.3s ease-out 1;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
`;

const CloseButton = styled.button`
  z-index: 10;
  position: fixed;
  top: 1em;
  left: 1em;
  width: 50px;
  height: 50px;
  padding: 0px;
  -webkit-tap-highlight-color: transparent;
  border-radius: 8px;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: white;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.5);
  border: none;

  &:hover,
  active {
    background: #cf9dfb;
    transform: translateY(5px);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
  }
`;

export default class AboutScreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {imageStatus:"loading"}
    }

    handleImageLoaded = () => {
        this.setState({
            imageStatus:"loaded"
        })
    }

  render() {
    return (
      <StyledBackground>
        <CloseButton>
          <Link to="/">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2531 9.42462C11.472 8.64358 10.2057 8.64358 9.42465 9.42462C8.6436 10.2057 8.6436 11.472 9.42465 12.2531L15.4351 18.2635L9.42465 24.2739C8.6436 25.0549 8.6436 26.3212 9.42465 27.1023C10.2057 27.8833 11.472 27.8833 12.2531 27.1023L18.2635 21.0919L24.2739 27.1023C25.0549 27.8833 26.3213 27.8833 27.1023 27.1023C27.8834 26.3212 27.8834 25.0549 27.1023 24.2739L21.0919 18.2635L27.1023 12.2531C27.8834 11.472 27.8834 10.2057 27.1023 9.42462C26.3213 8.64358 25.0549 8.64357 24.2739 9.42462L18.2635 15.435L12.2531 9.42462Z"
                fill="#5B0BA2"
              />
            </svg>
          </Link>
        </CloseButton>

        <StyledHeaderText status={this.state.imageStatus}>
          About
          <br /> Squarebusters
        </StyledHeaderText>
        <StyledHeaderImg
          src={AboutHeaderImg}
          onLoad={this.handleImageLoaded}
          status={this.state.imageStatus}
          alt="Astronout jumping into a planet"
        />
        <StyledAboutText
        status={this.state.imageStatus}>
          This game began as a pastime for me and my kids, Dylan and Ava. I
          thought it was a great way of nurturing their creativity and ability
          to improvise at a young age. <br /> <br />
          Going into an exponential future where machines can do more of the
          things humans can, shouldn’t we then do the things machines can’t do?
          Shouldn’t we instill them in our kids and prepare them for the future?{" "}
          <br /> <br /> To Imagine, create, innovate and empathise? I asked
          myself if our current education system values creativity? Would
          creativity be bred out of our children as they grow up? What can I do
          to keep it alive? <br /> <br /> So we took this game and tweaked it so
          parents can challenge and play alongside their kids as well. With it,
          we hope to make creativity a habit not just for the child, but for the
          parent as well.
        </StyledAboutText>
        <StyledHeaderImg
          src={AboutFooterImg}
          status={this.state.imageStatus}
          onLoad={this.handleImageLoaded}
          alt="Astronout jumping out of a planet"
        />
      </StyledBackground>
    );
  }
}
