import React, { Component } from 'react'
import styled from 'styled-components';
import {SkipIcon} from '../Icons';


const Button = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:136px;
height:55px;
box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
border: none;
border-radius: 8px;
background: #9B51E0;
color: #FEFAFA;
outline: none;
touch-action: manipulation;
pointer-events:auto;
transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);


&:active {
    background: 'black';
    transform: translateY(5px);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
  }
`;

const ButtonText = styled.span`
align-self: stretch;
font-family: 'Do Hyeon', sans-serif;
font-size:20px;
padding-left:8px;
`

export const SkipButton = props =>(
    <Button {...props}>
        <SkipIcon color="#FEFAFA"/>
        <ButtonText> {props.text ? props.text: "Skip"}</ButtonText>
</Button>);





