import React from "react";
import styled, { keyframes } from "styled-components";
import { ChallengeButton } from "../../components/buttons/ChallengeButton";
import { TickButton } from "../../components/buttons/TickButton";
import { UndoButton } from "../../components/buttons/UndoButton";
import Header from "../../components/game/Header";
import {
  Redirect
} from "react-router-dom";

import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import Canvas from "../../components/game/Canvas";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #504458;
  color: white;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
`;

const StyledChallengeBackground = styled(StyledBackground)`
  background: #5B0BA2;
`;


const PlayerTitleWrapper = styled.div`
  flex: 1 1 auto;
  font-family: Do Hyeon;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 20px;
  color: #ffefee;
  padding-top: 1rem;
  padding-left: 1rem;
`;

const StyledButtonsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100px;
  pointer-events: auto;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  left: 8px;
  right: 8px;
  top: 60px;
  bottom: 100px;
`;

const StyledBeginInfo = styled.div`
  font-family: Jua;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
`;

const StyledBeginDesc = styled(StyledBeginInfo)`
  font-family: Do Hyeon;
  color: #d1b1e6;
  font-size: 28px;
  text-align: center;
  padding: 16px 32px 0 32px;
  align-self: center;
`;

const StyledChallengeWordWrapper = styled.div`
display:flex;
z-index:10;
position:absolute;
top: 50px;
left:0px;
right:0px;
margin: 8px 8px 8px 8px;
color: #000000;
text-align:center;
font-family: Jua;
font-style: normal;
font-weight: normal;
height:30px;
line-height: normal;
font-size: 24px;
text-align: center;
flex-direction:column;
justify-content:space-around;
  background: #e6e2d6;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 8px;
  border: solid 1px #FFFFFF;
  pointer-events: none;
  animation: ${props => (props.status == "CHALLENGE_STARTED" ? fadeInDown : "none")}
    0.8s cubic-bezier(0.19, 1, 0.22, 1) 1.5s 1;
  animation-fill-mode: backwards;
  padding: 8px 16px 8px 16px;
`;

const StyledCanvasDraw = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  top: 136px;
  bottom: 100px;
  pointer-events: auto;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #ff6c64;
  box-sizing: border-box;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  animation: ${props => (props.status == "CHALLENGE_STARTED" ? fadeInUp : "none")}
    1s cubic-bezier(0.19, 1, 0.22, 1) 1;
    animation-delay: 2.0s;
  animation-fill-mode: backwards;
`;


const fadeInUp = keyframes`
from {
    opacity:0;
    transform: translateY(200%);
  }

  to {
    opacity:1;
    transform: translateY(0%);
  }
`;
const fadeInDown = keyframes`
from {
    opacity:0;
    transform: translateY(-200%);
  }

  to {
    opacity:1;
    transform: translateY(0%);
  }
`;

const AnimatedButton = styled.div`
  animation: ${fadeInUp} 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1;
  animation-delay: ${props => props.delay};
  animation-fill-mode: backwards;
`;

export default class ChallengeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineCount: 0,
      timerStatus:"loaded"
    };
  }
  componentDidUpdate() {}

  handleUndo = () => {
    console.log("Handling undo");
    if (this.state.lineCount <= 0) return;
    console.log(this.state.lineCount);
    this.setState({ lineCount: this.state.lineCount - 1 });
  };

  handleDoneClick = () => {
    const savedCanvas = this.canvasDraw.getSaveData();
    this.props.onDoneClick(savedCanvas);
  };

  handleAddLine = () => {
    console.log("Handling new line");
      this.setState({ lineCount: this.state.lineCount + 1 });
    
  };

  handleClearTimer = () => {
    this.setState({
      timerStatus:"done"
    });
    this.handleDoneClick()

  }

  handleFinishedDrawing = () => {
    this.setState({
      timerStatus:"started"
    })
  }



  render() {
    let screen;

    let undobutton;
    let donebutton;
    let animatedDrawingButtons;

    const beginButton = (
      <ChallengeButton text="Begin" onClick={this.props.onBeginClick} />
    );


    if (this.state.lineCount == 0) {
      undobutton = <UndoButton muted />;
      donebutton = <TickButton muted text="Done" />;
    } else {
      undobutton = <UndoButton onClick={this.handleUndo} />;
      donebutton = <TickButton onClick={this.handleDoneClick} text="Done" />;
    }

    animatedDrawingButtons = (
      <React.Fragment>
        <AnimatedButton delay="2.4s">{undobutton}</AnimatedButton>
        <AnimatedButton delay="2.6s">{donebutton}</AnimatedButton>
      </React.Fragment>
    );

    if (this.props.status == "CHALLENGE_BEGIN") {
      screen = (
        <StyledChallengeBackground>
            <PlayerTitleWrapper>
              <span> 🐵 PLAYER TWO </span>
            </PlayerTitleWrapper>
          
          <StyledInfoWrapper>
            <div>
              <StyledBeginInfo>It's time to bust some squares!</StyledBeginInfo>
              <StyledBeginDesc>
                Draw your opponent’s word or phrase in under 30 seconds.
              </StyledBeginDesc>
            </div>
          </StyledInfoWrapper>
          <StyledButtonsWrapper>
            <AnimatedButton delay="0.3s">{beginButton}</AnimatedButton>
          </StyledButtonsWrapper>
        </StyledChallengeBackground>
      );
    } else if (this.props.status == "CHALLENGE_STARTED") {

      if(this.state.timerStatus == "done"){
        return <Redirect to="/review"/>
      }

      screen = (<StyledBackground>
        <Header player={2}
        timerStatus={this.state.timerStatus}
        onClearTimer={this.handleClearTimer}></Header>
        <StyledChallengeWordWrapper status={this.props.status}>
          {this.props.challengeWord}
        </StyledChallengeWordWrapper>
        <StyledCanvasDraw status={this.props.status}>
        <Canvas
            ref={canvasDraw => (this.canvasDraw = canvasDraw)}
            width={this.props.canvasWidth}
            height={this.props.canvasHeight}
            savedCanvas={this.props.savedCanvas}
            onAddLine={this.handleAddLine}
            lineCount={this.state.lineCount}
            loadDelay={3000}
            onFinishedDrawing={this.handleFinishedDrawing}
          />
        </StyledCanvasDraw>
      <StyledButtonsWrapper>
        
        {animatedDrawingButtons}
      </StyledButtonsWrapper>
    </StyledBackground>)
    }

    return <React.Fragment>{screen}</React.Fragment>;
  }
}
