import React, { Component } from "react";
import styled from "styled-components";
import { PlayIcon } from "../Icons";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 70px;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid #e2c3ff;
  border-radius: 8px;
  background: #fefafa;
  color: #5b0ba2;
  outline: none;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  &: active {
    transform: translateY(5px);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
    border: 0.2px solid #33edff;
  }
`;

const ButtonText = styled.span`
  align-self: stretch;
  font-family: "Do Hyeon", sans-serif;
  font-size: 40px;
  padding-left: 24px;
`;

export const PlayButton = props => (
  <Button {...props}>
    <PlayIcon color="#9B51E0" />
    <ButtonText>Play</ButtonText>
  </Button>
);
