import React from "react";
import styled from "styled-components";
import { Logo } from "../../components/Logo";
import logo from '../../resources/newlogo.png';
import { PlayButton } from "../../components/buttons/PlayButton";
import { InfoButton } from "../../components/buttons/InfoButton";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink
} from "react-router-dom";

const StyledMainScreen = styled.div`
  position: fixed;
  top: 0%;
  bottom: 0%;
  background: linear-gradient(180deg, #A842FF 0%, #A842FF 55%, #2b054d 100%);
  width: 100%;
  user-select: none;
  -webkit-user-drag: none;
  color: white;
  overflow: hidden;
`;

const StyledLogoWrapper = styled.div`
  position: fixed;
  top: 5%;
  bottom: 45%;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

const StyledMenuButtonWrapper = styled.div`
position: fixed;
top: 60%;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
min-height: 230px;
pointer-events: none;
visibility: show;

a {
  text-decoration: none;
}
`;

const logostyled = {
  color: 'blue',
  width: '400px',
  height:'400px'
};

export default class MainScreen extends React.Component {

    componentDidMount(){
        
    }

    

  render() {
    return (
      <StyledMainScreen>
        <StyledLogoWrapper>
          <img style={logostyled} src={logo} />
        </StyledLogoWrapper>
        <StyledMenuButtonWrapper>
          <Link to="/game">
            <PlayButton />
          </Link>
          <Link to="/about">
            <InfoButton />
          </Link>
        </StyledMenuButtonWrapper>
      </StyledMainScreen>
    );
  }
}
