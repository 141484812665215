import React, { Component } from 'react'
import styled from 'styled-components';
import {RematchIcon} from '../Icons';


const Button = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:136px;
height:55px;
box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
border: 1px solid #B47DE8;
border-radius: 8px;
background: #7A0FD7;
color: #FEFAFA;
outline: none;
pointer-events:auto;
touch-action: manipulation;
transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);


&:active {
    transform: translateY(5px);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
  }
`;

const ButtonText = styled.span`
align-self: stretch;
font-family: 'Do Hyeon', sans-serif;
font-size:20px;
padding-left:8px;
`

export const RematchButton = props =>(
    <Button {...props}>
        <RematchIcon color="#FEFAFA"/>
        <ButtonText>Rematch</ButtonText>
</Button>);





