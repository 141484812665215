import React from "react";
import styled from "styled-components";

const StyledChallengeInput = styled.textarea`
vertical-align:middle;
font-family: Jua;
font-size:32px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-align: center;
color: #000000;
width:100%;
background:none;
border:none;
word-wrap: break-word
resize:none;
-webkit-tap-highlight-color: transparent;

&: focus, visited {
    color: #000000;
}

`;

const StyledInputWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
  background: #e6e2d6;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  border: solid 1px #FFFFFF;
  height:200px;
`;

export const ChallengeInput = props => (
  <StyledInputWrapper>
    <StyledChallengeInput
      onChange={props.onChange}
      maxLength="25"
      placeholder="Enter an object"
      rows="3"
      cols="15"
    />
  </StyledInputWrapper>
);
