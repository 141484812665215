import React from 'react';
import { POINT_CONVERSION_COMPRESSED } from 'constants';

const Icon = ({
    size = '1em',
    color = 'currentColor',
    title,
    children }) => (
    <svg width={size} height={size} viewBox="0 0 25 25">
    <title>{title}</title>
    {children}
    <style jsx="true">{` svg { vertical-align: middle; fill: ${color}; } `}</style>
    </svg> )

export const TickIcon = props => (
<svg width="25" height="21" viewBox="0 0 25 21" >
<path d="M8.49111 19.9875L0.366101 11.4419C-0.122034 10.9285 -0.122034 10.096 0.366101 9.58258L2.13383 7.7233C2.62196 7.20984 3.41347 7.20984 3.9016 7.7233L9.375 13.48L21.0984 1.14976C21.5865 0.636359 22.378 0.636359 22.8662 1.14976L24.6339 3.00904C25.122 3.52245 25.122 4.35487 24.6339 4.86833L10.2589 19.9875C9.7707 20.5009 8.97925 20.5009 8.49111 19.9875Z" fill={props.color}/>
</svg>)

export const SkipIcon = props => (
    <svg width="21" height="17" viewBox="0 0 21 17">
<path d="M20.5283 7.491L12.6533 0.928501C11.8084 0.227134 10.5 0.813658 10.5 1.93749V15.0625C10.5 16.1863 11.8084 16.7769 12.6533 16.0715L20.5283 9.50897C21.1559 8.98397 21.1559 8.016 20.5283 7.491ZM10.0283 7.491L2.15332 0.928501C1.3084 0.227134 0 0.813658 0 1.93749V15.0625C0 16.1863 1.3084 16.7769 2.15332 16.0715L10.0283 9.50897C10.6559 8.98397 10.6559 8.016 10.0283 7.491Z" fill={props.color}/>
</svg>)

export const UndoIcon = props => (
    <svg width="27" height="16" viewBox="0 0 27 16">
    <path d="M24.3 5.30407e-08H8.65955C8.30492 -6.9818e-05 7.95376 0.0688924 7.62613 0.202945C7.29851 0.336997 7.00084 0.53351 6.75016 0.78125L0.395505 7.05708C-0.131835 7.57792 -0.131835 8.42208 0.395505 8.9425L6.75016 15.2188C7.2564 15.7188 7.94321 16 8.65913 16H24.3C25.7913 16 27 14.8063 27 13.3333V2.66667C27 1.19375 25.7913 5.30407e-08 24.3 5.30407e-08ZM20.7272 10.5858C20.9909 10.8463 20.9909 11.2683 20.7272 11.5288L19.7729 12.4713C19.5092 12.7317 19.0819 12.7317 18.8182 12.4713L16.2001 9.88542L13.5819 12.4713C13.3183 12.7317 12.8909 12.7317 12.6273 12.4713L11.673 11.5288C11.4093 11.2683 11.4093 10.8463 11.673 10.5858L14.2911 8L11.673 5.41417C11.4093 5.15375 11.4093 4.73167 11.673 4.47125L12.6273 3.52875C12.8909 3.26833 13.3183 3.26833 13.5819 3.52875L16.2001 6.11458L18.8182 3.52875C19.0819 3.26833 19.5092 3.26833 19.7729 3.52875L20.7272 4.47125C20.9909 4.73167 20.9909 5.15375 20.7272 5.41417L18.1091 8L20.7272 10.5858Z" fill={props.color}/>
    </svg>)

export const PlayIcon = props => (
    <svg width="22" height="25" viewBox="0 0 22 25">
    <path d="M20.8417 10.4814L3.55546 0.320687C2.15095 -0.504474 0 0.296274 0 2.33721V22.6538C0 24.4847 1.99872 25.5882 3.55546 24.6703L20.8417 14.5144C22.3837 13.6112 22.3886 11.3847 20.8417 10.4814Z" fill={props.color}/>
    </svg>)

export const InfoIcon = props => (
    <svg width="29" height="29" viewBox="0 0 29 29">
    <path d="M14.5 0.453125C6.74267 0.453125 0.453125 6.74494 0.453125 14.5C0.453125 22.2596 6.74267 28.5469 14.5 28.5469C22.2573 28.5469 28.5469 22.2596 28.5469 14.5C28.5469 6.74494 22.2573 0.453125 14.5 0.453125ZM14.5 6.68359C15.8138 6.68359 16.8789 7.74866 16.8789 9.0625C16.8789 10.3763 15.8138 11.4414 14.5 11.4414C13.1862 11.4414 12.1211 10.3763 12.1211 9.0625C12.1211 7.74866 13.1862 6.68359 14.5 6.68359ZM17.6719 21.0703C17.6719 21.4457 17.3675 21.75 16.9922 21.75H12.0078C11.6325 21.75 11.3281 21.4457 11.3281 21.0703V19.7109C11.3281 19.3356 11.6325 19.0312 12.0078 19.0312H12.6875V15.4062H12.0078C11.6325 15.4062 11.3281 15.1019 11.3281 14.7266V13.3672C11.3281 12.9918 11.6325 12.6875 12.0078 12.6875H15.6328C16.0082 12.6875 16.3125 12.9918 16.3125 13.3672V19.0312H16.9922C17.3675 19.0312 17.6719 19.3356 17.6719 19.7109V21.0703Z" fill={props.color}/>
    </svg>)

export const LightningIcon = props => (
<svg width="14" height="22" viewBox="0 0 14 22">
    <path d="M12.9495 6.875H7.90074L9.76449 1.29766C9.93949 0.644531 9.43636 0 8.74949 0H2.44949C1.92449 0 1.47824 0.382422 1.40824 0.89375L0.00823622 11.2062C-0.0748888 11.825 0.415111 12.375 1.04949 12.375H6.24261L4.22574 20.7324C4.06824 21.3855 4.57574 22 5.24511 22C5.61261 22 5.96261 21.8109 6.15511 21.4844L13.8551 8.42188C14.262 7.73867 13.7589 6.875 12.9495 6.875Z" fill={props.color}/>
    </svg>)

export const RematchIcon = props => (
    <svg width="27" height="27" viewBox="0 0 27 27">
    <path d="M26.5732 13.5193C26.5669 17.0139 25.1898 20.1869 22.9512 22.5294L24.8346 24.4128C25.6319 25.2102 25.0672 26.5734 23.9396 26.5734L16.8701 26.5734C16.1711 26.5734 15.6045 26.0068 15.6045 25.3078L15.6045 18.2383C15.6045 17.1107 16.9677 16.546 17.7651 17.3433L19.9667 19.545C21.4907 17.9174 22.3349 15.8113 22.3542 13.5739C22.3962 8.70132 18.453 4.59411 13.4187 4.63624C8.64306 4.67621 4.63572 8.54797 4.63572 13.4953C4.63572 15.6641 5.40975 17.7139 6.82715 19.3293C7.04658 19.5794 7.03323 19.9571 6.79799 20.1924L4.70644 22.284C4.44952 22.5409 4.03065 22.5282 3.78712 22.2585C1.69246 19.9395 0.41697 16.8664 0.416971 13.4953C0.416971 6.27248 6.27223 0.417227 13.495 0.417175C20.7095 0.417122 26.5861 6.30476 26.5732 13.5193Z" fill={props.color}/>
    </svg>)

export const SaveIcon = props => (
    <svg width="27" height="27" viewBox="0 0 27 27">
<path d="M26.16 13.08C26.16 20.3056 20.3056 26.16 13.08 26.16C5.85435 26.16 0 20.3056 0 13.08C0 5.85435 5.85435 0 13.08 0C20.3056 0 26.16 5.85435 26.16 13.08ZM10.7594 6.96194V13.08H7.01995C6.45561 13.08 6.17081 13.7656 6.57165 14.1612L12.6317 20.1896C12.8796 20.4375 13.2751 20.4375 13.523 20.1896L19.5831 14.1612C19.9839 13.7604 19.6991 13.08 19.1348 13.08H15.4006V6.96194C15.4006 6.61384 15.1158 6.32903 14.7677 6.32903H11.3923C11.0442 6.32903 10.7594 6.61384 10.7594 6.96194Z" fill={props.color}/>
</svg>)
