import React from "react";
import styled, { keyframes } from "styled-components";

const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  pointer-events: none;
  -webkit-user-drag: none;
  width: 100%;
  height: 100%;
`;

export default class ImageScreen extends React.Component {

    render(){
        return(
            <StyledBackground>
                <img src={this.props.dataURL}/>
            </StyledBackground>
            
        )
    }
}