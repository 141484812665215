import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import ResizeObserver from "resize-observer-polyfill";
import drawImage from "./drawImage";

function midPointBtw(p1, p2) {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2
  };
}

const canvasStyle = {
  touchAction: "none",
  position: "absolute"
};

export default class Canvas extends React.Component {
  static defaultProps = {
    color: "#ffc600",
    width: "400px",
    height: "400px",
    brushRadius: 1,
    brushColor: "black",
    drawTimeOffset: 20,
    hideGrid: false,
    maxLines: 1000
  };

  constructor(props) {
    super(props);
    this.points = [];
    this.lines = [];

    this.isDrawing = false;

    //Canvas elements
    this.canvas = {};
    this.ctx = {};
    this.brushColor = props.brushColor;
  }

  canvasCallback = canvas => {
    if (canvas) {
      this.canvas["drawing"] = canvas;
      this.ctx["drawing"] = canvas.getContext("2d");
    }

    document.body.addEventListener(
      "touchstart",
      function(e) {
        if (e.target == canvas) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
    document.body.addEventListener(
      "touchend",
      function(e) {
        if (e.target == canvas) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
    document.body.addEventListener(
      "touchmove",
      function(e) {
        if (e.target == canvas) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
  };

  drawnCallback = canvas => {
    if (canvas) {
      this.canvas["drawn"] = canvas;
      this.ctx["drawn"] = canvas.getContext("2d");
    }
  };

  componentDidMount() {
    console.log("fired");
    if (this.props.savedCanvas != null) {
      this.loadSavedData(this.props.savedCanvas);
    }
    this.loop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lineCount == prevProps.lineCount - 1) {
      this.undo();
    }
  }

  loop = ({ once = false } = {}) => {
    if (!once) {
      window.requestAnimationFrame(() => {
        this.loop();
      });
    }
  };

  getSaveData = () => {
    // Construct and return the stringified saveData object
    const savedData = JSON.stringify({
      lines: this.lines,
      width: this.props.canvasWidth,
      height: this.props.canvasHeight
    });

    return savedData;
  };
s
  getDataURI = () => {
    //this.ctx.drawn.drawImage(this.canvas.drawing, 0, 0, width, height);
    let dataURI;
    dataURI = this.canvas.drawing.toDataURL( "image/png", 1.0);
    return dataURI
  }

  loadSavedData = data => {
    const { lines, width, height } = JSON.parse(data);
    this.lines = lines;
    console.log(lines);
    this.clearCanvas();

    setTimeout(props => {
      if (
        width === this.props.canvasWidth &&
        height === this.props.canvasHeight
      ) {
        this.drawLines(
          { lines: lines, immediate: false },
          this.props.onFinishedDrawing
        );
      } else {
        const scaleX = this.props.canvasWidth / width;
        const scaleY = this.props.canvasHeight / height;
        const scaleAvg = (scaleX + scaleY) / 2;

        this.drawLines(
          {
            lines: lines.map(line => ({
              ...line,
              points: line.points.map(p => ({
                x: p.x * scaleX,
                y: p.y * scaleY
              })),
              brushRadius: line.brushRadius * scaleAvg
            })),
            immediate: false
          },
          this.props.onFinishedDrawing
        );
      }
    }, this.props.loadDelay);
  };

  handleTouchStart = e => {
    e.preventDefault();
    const { x, y } = this.getPointerPosition(e);
    this.points.push({ x, y });
    this.mouseHasMoved = true;
  };

  handleTouchMove = e => {
    e.preventDefault();
    this.isDrawing = true;
    const { x, y } = this.getPointerPosition(e);
    this.handlePointerMove(x, y);
  };

  handleTouchEnd = e => {
    e.preventDefault();
    this.handleMouseUp(e);
    this.mouseHasMoved = true;
  };

  //Mouse events

  handleMouseDown = e => {
    e.preventDefault();
    console.log("Mouse down");
    const { initialX, initialY } = this.getPointerPosition(e);
    this.points.push({ initialX, initialY });
    this.isDrawing = true;
  };

  handleMouseMove = e => {
    e.preventDefault();
    const { x, y } = this.getPointerPosition(e);
    this.handlePointerMove(x, y);
    console.log("Mouse moved");
  };

  handleMouseUp = e => {
    e.preventDefault();
    this.isDrawing = false;
    this.saveLine();

    this.props.onAddLine();
  };

  saveLine = ({ brushColor, brushRadius } = {}) => {
    if (this.points.length < 2) return;

    const width = this.canvas.drawn.width;
    const height = this.canvas.drawn.height;

    if (this.lines.length < this.props.maxLines) {
      this.lines.push({
        points: [...this.points],
        brushColor: this.brushColor,
        brushRadius: brushRadius || this.props.brushRadius
      });

      this.ctx.drawn.drawImage(this.canvas.drawing, 0, 0, width, height);
    }

    this.points.length = 0;

    this.ctx.drawing.clearRect(0, 0, width, height);

    console.log("Line saved");
    console.log(this.lines);
  };

  getPointerPosition = e => {
    const rect = this.canvas.drawn.getBoundingClientRect();

    let clientX = e.clientX;
    let clientY = e.clientY;
    // use first touch if available
    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.changedTouches[0].clientY;
    }

    let posX = clientX - rect.left;
    let posY = clientY - rect.top;

    return {
      x: posX,
      y: posY
    };
  };

  //When pointer moves add snew point to line array

  handlePointerMove = (x, y) => {
    if (!this.isDrawing) return;
    this.points.push({ x, y });

    if (this.lines.length > this.props.maxLines - 1) {
      this.brushColor = "#ff6c64";
    } else {
      this.brushColor = "black";
    }
    this.drawPoints({
      points: this.points,
      brushColor: this.brushColor,
      brushRadius: this.props.brushRadius
    });
  };

  undo = () => {
    console.log("Undo fired");
    console.log(this.lines);

    this.lines = this.lines.slice(0, -1);

    this.ctx.drawing.clearRect(
      0,
      0,
      this.canvas.drawn.width,
      this.canvas.drawn.height
    );
    this.drawLines({ lines: this.lines, immediate: true });
    this.ctx.drawn.clearRect(
      0,
      0,
      this.canvas.drawn.width,
      this.canvas.drawn.height
    );
    this.ctx.drawn.drawImage(
      this.canvas.drawing,
      0,
      0,
      this.canvas.drawn.width,
      this.canvas.drawn.height
    );
    this.ctx.drawing.clearRect(
      0,
      0,
      this.canvas.drawn.width,
      this.canvas.drawn.height
    );
  };

  drawPoints = ({ points, brushColor, brushRadius }) => {
    if (points.length < 2) return;

    this.ctx.drawing.lineJoin = "round";
    this.ctx.drawing.lineCap = "round";
    this.ctx.drawing.strokeStyle = brushColor;
    this.ctx.drawing.lineWidth = brushRadius * 2;
    this.ctx.drawing.shadowBlur = 4;
    this.ctx.drawing.shadowColor = brushColor;

    let p1 = points[0];
    let p2 = points[1];

    this.ctx.drawing.moveTo(p2.x, p2.y);
    this.ctx.drawing.beginPath();

    for (var i = 1, len = points.length; i < len; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      var midPoint = midPointBtw(p1, p2);
      this.ctx.drawing.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);

      p1 = points[i];
      p2 = points[i + 1];
    }
    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    this.ctx.drawing.stroke();
  };

  drawLines = ({ lines, immediate }, callback) => {
    let elapsedTime = 0;
    let tick = immediate ? 0 : this.props.drawTimeOffset;

    lines.forEach((line, index, array) => {
      const { points, brushColor, brushRadius } = line;

      for (let i = 1; i < points.length; i++) {
        elapsedTime += tick;

        window.setTimeout(() => {
          this.drawPoints({
            points: points.slice(0, i + 1),
            brushColor,
            brushRadius
          });
          if(index === array.length - 1 &&
            i === points.length-1){
              if (callback != undefined) {
                elapsedTime += tick;
                window.setTimeout(callback(), elapsedTime);
              }

          }
        }, elapsedTime);
      }
    });

    console.log(elapsedTime);

    
  };

  clearCanvas = () => {
    this.ctx.drawn.clearRect(
      0,
      0,
      this.canvas.drawn.width,
      this.canvas.drawn.height
    );
    this.ctx.drawing.clearRect(
      0,
      0,
      this.canvas.drawing.width,
      this.canvas.drawing.height
    );
  };

  render() {
    return (
      <React.Fragment>
        <canvas
          ref={this.drawnCallback}
          style={canvasStyle}
          width={this.props.width}
          height={this.props.height}
        />
        <canvas
          ref={this.canvasCallback}
          style={canvasStyle}
          width={this.props.width}
          height={this.props.height}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
        />
      </React.Fragment>
    );
  }
}
