import React, { Component } from 'react'
import styled from 'styled-components';
import {SaveIcon} from '../Icons';


const Button = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:136px;
height:55px;
box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
border: 1px solid #C8FF00;
border-radius: 8px;
background: #ADDD00;
color: #273200;
outline: none;
opacity: ${props => props.active ? 1: 0.6};
pointer-events: ${props => props.active ? "auto" : "none"};
transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
-webkit-tap-highlight-color: transparent;
touch-action: manipulation;



&: active {
    background: 'black';
    transform: translateY(5px);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
  }
`;

const ButtonText = styled.span`
align-self: stretch;
font-family: 'Do Hyeon', sans-serif;
font-size:20px;
padding-left:8px;
`

export const SaveButton = props =>(
    <Button {...props}>
        <SaveIcon color="#273200"/>
        <ButtonText>Save</ButtonText>
</Button>);





