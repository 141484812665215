
import React, { Component } from 'react'
import styled from 'styled-components';
import {TickIcon, SkipIcon, UndoIcon, PlayIcon, InfoIcon, LightningIcon, RematchIcon} from './Icons';

const ButtonText = styled.span`
align-self: stretch;
font-family: 'Do Hyeon', sans-serif;
font-size:20px;
padding-left:5px;
`

export const Button = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:136px;
height:55px;
box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
border-radius: 8px;


:hover{
   
  box-shadow: 0 5px #666;
}

${props => {
    if (props.primary) {
      return `
        background: #ADDD00;
        color: #273200" ;
      `;
    } else if (props.challenge){
        return `
        background: #FED845;
        color: #332A10";
        border-color: #FFE993;
        border: 1px solid #FFE993;
      `;
    }
  }}

`;



function Icon (icontype){
    switch(icontype){
        case "tick":
        return <TickIcon/>

        case "skip":
        return  <SkipIcon/>

        case "undo":
        return <UndoIcon/>

        case "play":
        return <PlayIcon/>

        case "info":
        return <InfoIcon/>

        case "lightning":
        return <LightningIcon/>

        case "rematch":
        return <RematchIcon/>

      
        return
    }
    
}

const ButtonTextTitles = {
    accept: "Accept",
    skip: "Skip",
    undo: "Undo",
    challenge: "Challenge",
    ready: "Ready",
    done: "Done",
    rematch: "Rematch",
    save: "Save"
}

export const IconButton = props =>(
    <Button {...props}>
        {Icon(props.icon)}
        <ButtonText>{props.text}</ButtonText>
</Button>);



