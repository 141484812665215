import React, { Component } from "react";
import styled, {keyframes} from "styled-components";



const jackInTheBox = keyframes`
from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }`

  const StyledTimerWrapper = styled.div`
  visibility:${props => props.status== "started" ? "visible" : "hidden"}
animation:${props => props.status== "started" ? jackInTheBox : "none"} 0.6s 1;
animation-fill-mode: backwards;
`


export default class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    timerStatus:"loaded",
      countdownTime: 30,
      currentTime: 30
    };
  }

  componentDidMount() {
    this.setState({
        status: this.props.status
    });
  }

  componentDidUpdate(){
    console.log("fired")
    if(this.state.currentTime<=0){
      this.clearTimer()
    }

    if(this.props.status=="started"){
        if(this.state.timerStatus != "ticking"){
            this.startTimer()
        }
      
    }
  }

  startTimer = () => {
    this.timer = setInterval(() => {
      this.setState({
        currentTime: this.state.currentTime - 1,
        timerStatus: "ticking"
      });
    }, 1000);
    
  };

  clearTimer = () => {
    clearInterval(this.timer);
    this.props.onClearTimer();
  };

  render() {
    
    let timer = "⏱ " + this.state.currentTime;

    return <StyledTimerWrapper status={this.props.status}>{timer}</StyledTimerWrapper>;
  }
}
