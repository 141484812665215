import React, { Component } from 'react'
import styled, {keyframes} from 'styled-components';
import {TickIcon} from '../Icons';



const Button = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:136px;
height:55px;
box-shadow: ${props => props.muted ? "none" : "0px 8px 0px rgba(0, 0, 0, 0.25)"};
border: ${props => props.muted ? "none" : "1px solid #C8FF00"}; 
border-radius: 8px;
background: ${props => props.muted ? "#708F00" : "#ADDD00"};
color: #273200;
opacity: ${props => props.muted ? 0.5 : 1};
outline: none;
transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
pointer-events:auto;
-webkit-tap-highlight-color: transparent;
touch-action: manipulation;


&: active {
    transform: ${props => props.muted ? "none" : "translateY(5px)"};
    box-shadow: ${props => props.muted ? "none" : "0px 5px 0px rgba(0, 0, 0, 0.9)"};
  }
`;

const ButtonText = styled.span`
align-self: stretch;
font-family: 'Do Hyeon', sans-serif;
font-size:20px;
padding-left:8px;
`


export const TickButton = props =>(
    <Button {...props}>
        <TickIcon color="#273200"/>
        <ButtonText>{props.text}</ButtonText>
</Button>);





