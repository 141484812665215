import React from "react";
import styled from "styled-components";
import { InfoIcon } from "../Icons";

const Button = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:176px;
height:60px;
box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.50);

border: none;
border-radius: 8px;
background: #E5E5E5;
color: #504458;
outline: none;
-webkit-tap-highlight-color: transparent;
pointer-events:auto;
transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
touch-action: manipulation;


&: active {
    transform: translateY(5px);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
    border: 0.2px solid #33EDFF;
  }
`;

const ButtonText = styled.span`
  align-self: stretch;
  font-family: "Do Hyeon", sans-serif;
  font-size: 32px;
  padding-left: 16px;
`;

export const InfoButton = props => (
  <Button {...props}>
    <InfoIcon color="#504458" />
    <ButtonText>About</ButtonText>
  </Button>
);
